import { parse as parseRegion } from '@/types/regionIndex';
import { parse as parseTopic } from '@/types/topicIndex';
import { parse as parsePerson } from '@/types/personIndex';

export type FilterItem = {
    id: string,
    title: string,
    isChecked: boolean,
    default?: boolean,
}

export type NestedFilterItem = FilterItem & {
    subItems: FilterItem[],
    subMenuOpen: boolean,
    default?: boolean,
}

export type TopicFilterItem = FilterItem;
export type RegionFilterItem = NestedFilterItem;
export type YearFilterItem = FilterItem;
export type ExpertFilterItem = FilterItem;

export type FilterData = {
    topics: TopicFilterItem[],
    regions: RegionFilterItem[],
    years: YearFilterItem[],
    experts: ExpertFilterItem[],
}

export type SelectedFilters = {
    topics: { id: string, title: string }[],
    regions: { id: string, title: string, isChecked: boolean, subItems: { id: string, title: string, isChecked: boolean }[] }[],
    years: { id: string, title: string }[],
    experts: { id: string, title: string }[],
}

export function useFilterData({ pageLanguage }: { pageLanguage: string }) {
    const { currentCenter } = useCenters();
    const center = currentCenter.value.id;
    
    const { docs: topics } = useAsyncMeiliAllDocuments(`topics_${pageLanguage}`, { filter: `status = published AND centers = ${center}` });
    const { docs: regionsOrCountries } = useAsyncMeiliAllDocuments(`regions_${pageLanguage}`, { filter: `status = published AND centers = ${center}` });
    const { docs: experts } = useAsyncMeiliAllDocuments(`people_${pageLanguage}`, { filter: `status = published AND center = ${center} AND isArchived != TRUE AND personDetails.personType = carnegieExpert` });

    const formattedRegions = computed<RegionFilterItem[]>(() => {
        const parsedRegionsOrCountries = regionsOrCountries.value.map(parseRegion);
        const regions = parsedRegionsOrCountries.filter((regionOrCountry: any) => regionOrCountry.type === 'region');
        const countries = parsedRegionsOrCountries.filter((regionOrCountry: any) => regionOrCountry.type === 'country');

        return regions.map(region => ({
                id: region.id,
                title: region.title,
                isChecked: false,
                subMenuOpen: false,
                subItems: countries.filter(country => country.regionIds?.includes(region.id)).map(country => ({
                    id: country.id,
                    title: country.title,
                    isChecked: false,
                }))
            })
        );
    })

    const formattedTopics =  computed<TopicFilterItem[]>(() => {
        const parsedTopics = topics.value.map(parseTopic);
        return parsedTopics.map(topic => ({
            id: topic.id,
            title: topic.title,
            isChecked: false,
        }));
    });

    const formattedYears = computed<YearFilterItem[]>(() => {
        const currentYear = new Date().getFullYear();
        const startYear = 2003;
        return Array.from({ length: currentYear - startYear + 1 }, (_, i) => ({
            id: (startYear + i).toString(),
            title: (startYear + i).toString(),
            isChecked: false,
        }));
    });

    const formattedExperts = computed<ExpertFilterItem[]>(() => {
        const parsedExperts = experts.value.map(parsePerson);
        return parsedExperts.map(expert => ({
            id: expert.id,
            title: expert.title,
            lastName: expert.lastName,
            isChecked: false,
        }));
    });

    const data = computed<FilterData>(() => {
        return {
            topics: formattedTopics.value,
            regions: formattedRegions.value,
            experts: formattedExperts.value,
            years: formattedYears.value,
        }
    });

    return { data, getSelectedFilters };
};

function getSelectedFilters(filterData: FilterData): SelectedFilters {
    const copy = structuredClone(filterData);

    const selectedTopics = copy.topics.filter(topic => topic.isChecked).map(topic => ({ id: topic.id, title: topic.title }));
    const selectedYears = copy.years.filter(year => year.isChecked).map(year => ({ id: year.id, title: year.title }));
    const selectedExperts = copy.experts.filter(expert => expert.isChecked).map(expert => ({ id: expert.id, title: expert.title }));
    const selectedRegions: SelectedFilters['regions'] = [];

    for (const region of copy.regions) {
        const formattedRegion = { id: region.id, title: region.title, subItems: region.subItems.filter(subItem => subItem.isChecked), isChecked: region.isChecked };
        if (region.isChecked) {
            selectedRegions.push(formattedRegion);
        } else {
            for (const subRegion of region.subItems) {
                if (subRegion.isChecked) {
                    selectedRegions.push(formattedRegion);
                    break;
                }
            }
        }
    }

    return {
        topics: selectedTopics,
        regions: selectedRegions,
        experts: selectedExperts,
        years: selectedYears,
    };
}
