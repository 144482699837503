import { MeiliSearch } from 'meilisearch'

export const useAsyncMeiliAllDocuments = (index: string, options?: { filter?: string; }) => {
    const meili = inject<MeiliSearch>('meilisearch-client');
    if (!meili) {
        throw new Error('MeiliSearch client not found')
    }

    const loading = ref<boolean>(false)
    const docs = ref<any[]>([])
    const errors = ref<string[]>([])

    watchEffect(() => {
        loading.value = true;
        getAllDocuments(meili, index, { filter: options?.filter }).then((results) => {
            docs.value = results;
        }).catch((error) => {
            errors.value = [error.message];
            loading.value = false;
        }).finally(() => {
            loading.value = false;
        })
    })

    return { docs, loading, errors }
}

async function getAllDocuments(client: MeiliSearch, index: string, options?: { filter?: string }) {
    let offset = 0;
    let limit = 250;
    let docs: any[] = [];
    while (true) {
        const results = await client.index(index).getDocuments({ limit, offset, filter: options?.filter });
        docs = docs.concat(results.results);
        offset += limit;
        if (results.total < offset) break;
    }

    return docs;
}
